import * as React from "react";
import {FileNode} from "gatsby-plugin-image/dist/src/components/hooks";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {FC} from "react";

const Image: FC<{image: FileNode | undefined}> = (props )=>{
    return props.image ? <GatsbyImage image={getImage(props.image)!} imgStyle={{objectPosition: 'center'}} alt=""/> : null;
}

export default Image;

import * as React from "react";
import './article-header.scss'
import {Link} from "gatsby";
import Date from "../elements/date";
import Navigation from "./navigation";
import {FileNode} from "gatsby-plugin-image/dist/src/components/hooks";
import {CMSFile} from "../types";
import Image from "./image";
import MobileNavigation from "./mobile-navigation";

interface ArticleHeaderProps {
    title: string,
    image?: FileNode;
    date?: string
    activePage: string
}

const ArticleHeader = ({title, image, date, activePage}: ArticleHeaderProps) => {
    return (
        <div className="article-header grid">
            <Navigation activePage={activePage}/>
            <MobileNavigation activePage={activePage}/>
            <Link to="/" className="article-header__back-link">
                Tijo Salverda
            </Link>
            <div className={image ? "article-header__image" : "article-header__no-image"}>
                <Image image={image}/>
            </div>
            <h1 className="article-header__title" lang="en">
                {title}
            </h1>
            {date && <div className="article-header__date">
                <Date date={date}/>
            </div>}
        </div>
    );
};

export default ArticleHeader;


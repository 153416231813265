import * as React from "react"
import {ReactNode} from "react"
import Page from "./page";
import ArticleHeader from "./article-header";
import Section from "./section";
import ArticleText from "./article-text";
import SectionTitle from "../elements/section-title";
import NewsLayout from "./news-layout";
import Teaser, {Type} from "./teaser";
import Footer from "./footer";
import "./base-article.scss";
import {FileNode} from "gatsby-plugin-image/dist/src/components/hooks";
import {Article} from "../types";

interface BaseArticleProps {
    content: string;
    title: string;
    image?: FileNode;
    teaserSectionTitle: string;
    teaseredArticles: any;
    moreTeaseredArticlesSectionTitle?: string;
    moreTeaseredArticles?: any;
    sideContent: ReactNode;
    date?: string;
    activePage: string
}

export const BaseArticle = ({
                                content,
                                title,
                                image,
                                teaserSectionTitle,
                                teaseredArticles,
                                sideContent,
                                moreTeaseredArticles,
                                moreTeaseredArticlesSectionTitle,
                                date,
                                activePage
                            }: BaseArticleProps) => {
    return (<div className="base-article">
            <title>{title}</title>

            <Page>
                <ArticleHeader title={title} image={image} date={date} activePage={activePage}/>
                <Section>
                    <ArticleText htmlContent={content} sideContent={sideContent}/>
                </Section>
                {teaseredArticles.length > 0 &&
                    <Section>
                        <SectionTitle title={teaserSectionTitle}/>
                        <NewsLayout>
                            {teaseredArticles.map((a: Article) =>
                                (<Teaser date={a.frontmatter.date} title={a.frontmatter.title} text={a.excerpt}
                                         actionPath={a.fields.slug} type={Type.SMALL}/>)
                            )}
                        </NewsLayout>
                    </Section>
                }
                {moreTeaseredArticles && moreTeaseredArticles.length > 0 &&
                    <Section>
                        <SectionTitle title={moreTeaseredArticlesSectionTitle || ''}/>
                        <NewsLayout>
                            {moreTeaseredArticles.map((a: Article) =>
                                (<Teaser date={a.frontmatter.date} title={a.frontmatter.title} text={a.excerpt}
                                         actionPath={a.fields.slug} type={Type.SMALL}/>)
                            )}
                        </NewsLayout>
                    </Section>
                }
                <Footer/>
            </Page>

        </div>
    )
}

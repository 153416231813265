import React, {FC, useState} from "react";
import './mobile-navigation.scss'
import {Link} from "gatsby";
import classNames from "classnames";

interface MobileNavigationProps {
    activePage?: string;
}

const MobileNavigation: FC<MobileNavigationProps> = ({ activePage }) => {

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  return (
    <div className={`mobile-navigation ${
      isNavCollapsed ? "" : "mobile-navigation--open"
    }`}>
      <button
        className="mobile-navigation__toggle-button"
        type="button"
        data-toggle="collapse"
        data-target="#navbarsExample09"
        aria-controls="navbarsExample09"
        aria-expanded={!isNavCollapsed ? true : false}
        aria-label="Toggle navigation"
        onClick={handleNavCollapse}
      >
        <div id="nav-icon4">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
      <div className="mobile-navigation__container">
    <ul className="mobile-navigation__items">
      <li className={classNames("mobile-navigation__item", {
        "mobile-navigation__item--active": activePage === "home",
      })}>
        <Link to="/">Home</Link>
      </li>
      <li className={classNames("mobile-navigation__item", {
        "mobile-navigation__item--active": activePage === "newsandthoughts",
      })}>
        <Link to="/news_and_thoughts">News and thoughts</Link>
      </li>
      <li className={classNames("mobile-navigation__item", {
        "mobile-navigation__item--active": activePage === "transition",
      })}>
        <Link to="/transition">Transition</Link>
      </li>
      <li className={classNames("mobile-navigation__item", {
        "mobile-navigation__item--active": activePage === "research",
      })}>
        <Link to="/research">Research</Link>
      </li>
      <li className={classNames("mobile-navigation__item", {
        "mobile-navigation__item--active": activePage === "about",
      })}>
        <Link to="/about">About</Link>
      </li>
      <li className={classNames("mobile-navigation__item", {
        "mobile-navigation__item--active": activePage === "publications",
      })}>
        <Link to="/publications">Publications</Link>
      </li>
    </ul>
    </div>
    </div>
  );
};

export default MobileNavigation;


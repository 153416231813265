import * as React from "react"
import '../neat-basics/index.scss'
import "./theming.scss"
import "../styles/styles.scss"
import {graphql} from "gatsby";
import {Article,TransitionPageProps} from "../types";
import Main from "../modules/main";
import {BaseArticle} from "../modules/base-article";

const Transition = (page: { data: { transition: TransitionPageProps, thougthArticles: {nodes: Article[]} };   }) => {
    const article = page.data.transition
    const relatedThoughtsTextValues = article.frontmatter.relatedThoughts
    const relatedThougthArticles = page.data.thougthArticles ? page.data.thougthArticles.nodes.filter(t => relatedThoughtsTextValues?.includes(t.frontmatter.title)) : []

    return (<>
            <Main title="Research">
                <BaseArticle
                    content={article.html}
                    title={article.frontmatter.title}
                    image={article.frontmatter.image}
                    teaserSectionTitle="Related Thoughts"
                    sideContent={[]}
                    teaseredArticles={relatedThougthArticles}
                    activePage="transition"
                    // sideContent={<div className="related-publications"><div className="related-publications__title">Related Publications:</div><ul>{relatedPublications.map(p => <li><a target="_blank" rel="noopener noreferrer" href={p.frontmatter.url}>{p.frontmatter.text}</a></li>)}</ul></div>}

                    // teaseredArticles={olderArticles.slice(0, 2)}
                    // sideContent={latestNews && <NewsItem text={latestNews.frontmatter?.text} date={latestNews.frontmatter.date} url={latestNews.frontmatter.url} label={latestNews.frontmatter.linkLabel} />}
                />
            </Main></>
    )
}

export default Transition

export const pageQuery = graphql`
  query Transition {
      transition: markdownRemark(fields: {slug: {eq: "/single-pages/transition/"}}) {
      html
      frontmatter {
        title
        image {
           childImageSharp {
             gatsbyImageData(width: 1500)
           }
        }
        relatedThoughts
      }
    }
    thougthArticles: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "thought_article"}}}, sort: { fields: [frontmatter___date], order: DESC}) {
      nodes {
        id
        html
        excerpt(format: PLAIN, pruneLength: 5000)
        fields {
          slug
        }
        frontmatter {
          date
          title
        }
      }
    }
  }
`

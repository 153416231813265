import * as React from "react";
import './page.scss';
import {FC,  ReactNode} from "react";

interface PageProps {
  children: ReactNode;
}

const Page: FC<PageProps> = ({ children }) => {
  return (
    <div className="page">
      <div className="page__content-wrap">
        {children}
      </div>
    </div>
  );
};

export default Page;


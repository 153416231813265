import * as React from "react";
import './date.scss';
import {FC} from "react";

interface DateProps {
  date: string;
  onlyYear?: boolean;
}

const Date: FC<DateProps> = ({ date, onlyYear }) => {
  const parsedDate = new global.Date(date);
  return (
    <div className="date">
      {
        onlyYear ? parsedDate.getFullYear() : parsedDate.toLocaleDateString(
            'en-gb',
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }
        )
      }
    </div>
  );
};

export default Date;


import * as React from "react";
import PropTypes from "prop-types";
import './section.scss';
import classNames from "classnames";


const Section = ({ children, grey }) => {
  return (
    <div className={classNames("section grid", {"grid--grey": grey})}>
      {children}
    </div>
  );
};

export default Section;


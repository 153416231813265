import * as React from "react";
import './footer.scss'
import {Link} from "gatsby";

const Footer = () => {
  return (
    <div className="footer grid">
      <div className="footer__content">
        © 2021 Tijo Salverda {' '} •{' '}
        <Link to="/imprint">Imprint</Link>{' '}
      </div>
    </div>
  );
};

export default Footer;


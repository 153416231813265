import * as React from "react";
import './article-text.scss';
import {ReactNode} from "react";

interface ArticleTextProps {
    children?: ReactNode;
    htmlContent?: string;
    sideContent?: ReactNode;
}

const ArticleText = ({ children, htmlContent, sideContent}: ArticleTextProps) => {
  return (
    <div className="article-text">
      <div className="article-text__container" dangerouslySetInnerHTML={htmlContent ? { __html: htmlContent } : undefined}/>
        {children}
        {sideContent}
    </div>
  );
};

export default ArticleText;
import * as React from "react";
import './navigation.scss'
import {Link} from "gatsby";
import classNames from "classnames";

interface NavigationProps {
    activePage?: string;
}

const Navigation = ({activePage}: NavigationProps) => {
    return (
        <ul className="navigation">
            <li className={classNames("navigation__item", {
                "navigation__item--active": activePage === "newsandthoughts",
            })}>
                <Link to="/news_and_thoughts">News and thoughts</Link>
            </li>
            <li className={classNames("navigation__item", {
                "navigation__item--active": activePage === "transition",
            })}>
                <Link to="/transition">Transition</Link>
            </li>
            <li className={classNames("navigation__item", {
                "navigation__item--active": activePage === "research",
            })}>
                <Link to="/research">Research</Link>
            </li>
            <li className={classNames("navigation__item", {
                "navigation__item--active": activePage === "about",
            })}>
                <Link to="/about">About</Link>
            </li>
            <li className={classNames("navigation__item", {
                "navigation__item--active": activePage === "publications",
            })}>
                <Link to="/publications">Publications</Link>
            </li>
        </ul>
    );
};

export default Navigation;

